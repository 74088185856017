@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.scores_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    padding: 0 20px 10px 20px;
}

.score_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.daily_score,.position_container{
    width: 140px;
    background-color: #0D0D0D;
    padding: 8px 0px 8px 0px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 15px rgba(0,0,0,0.3);
}

.score{
    font-size: 20px;
    color:#fff;
    font-weight: 800;
    font-family: 'Inter',sans-serif;
    padding: 0;
    margin: 0;
}

.info{
    width: 100%;
    color: #0D0D0D;
    font-size: 13px;
    font-family: 'Inter',sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.position_container{
    background-color: #FFCB05;
}

.position_container p{
    color: #0D0D0D;
}