.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: absolute; */
    height: 100vh;
}

.top{
    width: 60%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: white;
    top: 0;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.image{
    padding: 2rem;
}

.footer{
    width: 60%;
    text-align: center;
}

.welcome_title{
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
}

.footer_text{
    font-size: 1rem;
    font-weight: 500;
}

.footer_img{
    width: 5rem;
    height: 5rem;
}