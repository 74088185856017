.modal_container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100dvh;
    background-color: #0a0d12a7;
    display: grid;
    place-content: center;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  .modal_container.modal_show {
    opacity: 1;
    visibility: visible;
    /* transform: translate(-50%, -50%) scale(1); */
  }
  

.modal_sub_container{
    width: 350px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 16px rgba(0,0,0,0.5);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.icon img{
    width:70px;
    height:70px;
    object-fit: contain;
}

.icon_style{
    color: red;
    width: 30rem;
    height: 15rem;
}

.modal_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.main_heading p{
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: black;
    /* background: linear-gradient(45deg,rgba(0,0,0,1) 0%,rgb(243, 13, 13)); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    font-weight: 600;
}

.paragraph p{
    /* background: linear-gradient(to left,rgba(0,0,0,1),rgb(243, 13, 13)); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    font-size: 2rem;
    color: #181D27;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
    text-align: center;
}

.btn_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.6rem;
    width: 100%;
}

.confirm_btn{
    width: 100%;
    padding: 1.3rem 1rem;
    background-color: #00263a;
    outline: none;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    transition: all 0.4ms;
}

/* .confirm_btn:hover{
    background: linear-gradient(180deg,#00263a,#03344e);
} */

.cancel_btn{
    /* background: linear-gradient(45deg,rgb(148, 148, 148) 0%,rgb(99, 99, 99)); */
    border-radius: 4px;
    border: 1px solid #D5D7DA;
    background: #fff;
    color: black;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    width: 100%;
    padding: 1.3rem 1rem;
}

.cancel_btn:hover{
    background: #D5D7DA;
}

@media (width<=350px){
    .modal_sub_container{
        width: 300px;
    }
}

.spinner {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #dbdcef;
    border-right-color: #ffcf19;
    animation: spinner-d3wgkg 1s infinite linear;
 }
 
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }

/* HTML: <div class="loader"></div> */

.loader {
   width: 56px;
   height: 26.9px;
   background: radial-gradient(circle closest-side,#c5c5e9 90%,#0000) 0%   50%,
          radial-gradient(circle closest-side,#c5c5e9 90%,#0000) 50%  50%,
          radial-gradient(circle closest-side,#c5c5e9 90%,#0000) 100% 50%;
   background-size: calc(100%/3) 13.4px;
   background-repeat: no-repeat;
   animation: dots-7ar3yq 1s infinite linear;
}

@keyframes dots-7ar3yq {
   20% {
      background-position: 0%   0%, 50%  50%,100%  50%;
   }

   40% {
      background-position: 0% 100%, 50%   0%,100%  50%;
   }

   60% {
      background-position: 0%  50%, 50% 100%,100%   0%;
   }

   80% {
      background-position: 0%  50%, 50%  50%,100% 100%;
   }
}