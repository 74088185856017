
.main{
    position: relative;
    /* width: 27rem; */
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, #FFCB05 0%, #FFFFFF 100%);
    /* padding: 2rem; */

 }

 .bg{
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     min-height: 100dvh;
     z-index: 0;
 }
 .bg .image{
     width: 100%;
     opacity: 0.6;
     min-height: 100dvh;
     height: auto;
     object-fit: contain;
 }
