
.main{
    /* / padding: 2rem; / */
    /* / position: absolute; / */
    position: absolute;
    top: 7rem;
    /* top:40%; */
    /* left: 50%; */
    /* transform: translate(-50%,-40%); */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.top{
    width: 60%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: white;
    top: 0;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.animation{
    height: 8rem;
    width: 8rem;
    /* / position: absolute; / */
    bottom: 5rem;
    margin-top: 3rem;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.logo img{
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    box-shadow: rgb(248 248 250) 0px 50px 100px -20px, rgb(251 250 250) 10px 30px 60px -30px, rgb(248 250 251) 10px -2px 6px 0px inset;
}

 .tabs_container{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 99;
     padding: 1rem 0 1rem 0;
 }

 .tabs_sub_container{
     display: grid;
     align-items: center;
     grid-template-columns: 1fr 1fr;
     /* / width: 90%; / */
     width: 20rem;
     border-bottom: 0.1rem solid #E92230;
     cursor: pointer;
 }
 .tab_1{
     display: flex;
     justify-content: flex-start;
     align-items: center;
     /* / padding: 0 1rem 0 1rem; / */
     height: 100%;
 }
 .tab_2{
     display: flex;
     justify-content: flex-end;
     align-items: center;
     /* / padding: 1rem 1rem 1rem 1rem; / */
     height: 100%;
     border-bottom: 0.2rem solid #E92230;
 }
 
 .tab_2 .tab{
     font-size: 2rem;
     font-weight: 800;
     color: black;
     font-family: 'Inter',sans-serif;
 }
 
 .tab_1 .tab{
     font-size: 2rem;
     font-family: 'Inter',sans-serif;
     color: black;
 }

 .tab{
    margin: 0;
 }

 
 .form_container{
     z-index: 99;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     padding: 10px;
     margin-top: 20px;
 }
 
 .form{
     display: flex;
     /* / justify-content: center; / */
     align-items: center;
     width: 100%;
     flex-direction: column;
     gap: 2rem;
     /* / height: 20rem; / */
 }
 
 .input{
    width: 100%;
     border: none;
     outline: none;
     background: transparent;
     height: 55px;
     color: black;
     font-size: 16px;
     font-family: 'Inter',sans-serif;
     /* / font-weight:500; / */
 }
 
 .input::placeholder{
     color: grey;
 }
 
 .input_group{
     width: 100%;
     background-color: #eff2f5;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     gap: 1rem;
     padding: 0 1rem 0 1rem;
     border-radius: 0.4rem;
 }
 
 .country_code{
     color: grey;
     font-size: 16px;
     font-weight: 800;
 }
 
 .selector{
     /* / width: 25rem; / */
     /* / height: 3rem; / */
     height: fit-content;
     gap: 0.5rem;
     width: 90%;
     font-size: 1.3rem !important;
     cursor: pointer;
 }
 
 
 .subscribe_btn{
     /* / margin-top: 8rem; / */
     width: 90%;
     /* / width: 27rem; / */
     display: flex;
     justify-content:center;
     align-items: center;
     padding: 1rem 1rem 1rem 1rem;
     font-size: 2rem;
     font-family: 'Inter',sans-serif;
     font-weight: 900;
     color: white;
     /* background-color: #E92230; */
     background-color: #00263a;
     text-transform: uppercase;
     /* / letter-spacing: 0.2rem; / */
     border: none;
     outline: none;
     border-radius: 1rem;
     box-shadow: 0 1rem 1rem rgba(0,0,0,0.3);
     cursor: pointer;
 }
 .subscribe_btn_down{
    margin-top: 8rem;
    width: 90%;
    /* / width: 27rem; / */
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    font-size: 1rem;
    font-family: 'Inter',sans-serif;
    font-weight: 900;
    color: white;
    /* background-color: #E92230; */
    background-color: #00263a;
    text-transform: uppercase;
    /* / letter-spacing: 0.2rem; / */
    border: none;
    outline: none;
    border-radius: 1rem;
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.3);
    cursor: pointer;
}
 
 
 .footer_container{
    position: absolute;
     bottom: 0px;
     bottom:1.5rem;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     z-index: 99;
 }

 .footer{
    height: 6rem;
    width: 10rem;
 }

 .footer_sub_container{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     /* / gap: 1rem; / */
 }
 .footer_text{
     /* / padding: 1rem 1rem 1rem 1rem; / */
     /* / font-size: 1.4rem; / */
     font-family: 'Inter',sans-serif;
     color: grey;
     text-align: center;
 }
 
 .radio_btns{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px 0 30px;
 }

 .radio_btn{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
 }

 .label{
    margin: 0;
    padding-top: 4px;
    font-size: 16px;
    color: #333;
    font-family: 'Inter',sans-serif;
 }

 .radio{
    width: 15px;
    height: 15px;
 }

 @media screen and (max-height:650px){
    .footer_container{
        position: absolute;
         bottom:-8.5rem;
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         z-index: 99;
     }
 }



 /* SPINNER STYLES.... */

 .spinner {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #dbdcef;
    border-right-color: #ffcf19;
    animation: spinner-d3wgkg 1s infinite linear;
 }
 
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }