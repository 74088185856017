
.main{
    /* padding: 2rem; */
    position: absolute;
    top: 7rem;
    /* top: 40%; */
    /* left: 50%; */
    /* transform: translate(-50%,-40%); */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.logo img{
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    box-shadow: rgb(248 248 250) 0px 50px 100px -20px, rgb(251 250 250) 10px 30px 60px -30px, rgb(248 250 251) 10px -2px 6px 0px inset;
}

 .tabs_container{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 99;
     padding: 1rem 0 1rem 0;
 }

 .tabs_sub_container{
     display: grid;
     align-items: center;
     grid-template-columns: 1fr 1fr;
     /* width: 90%; */
     width: 20rem;
     border-bottom: 0.1rem solid #E92230;
     cursor: pointer;
 }
 .tab_1{
     display: flex;
     justify-content: flex-start;
     align-items: center;
     /* padding: 0 1rem 0 1rem; */
     height: 100%;
     border-bottom: 0.2rem solid #E92230;
 }
 .tab_2{
     display: flex;
     justify-content: flex-end;
     align-items: center;
     /* padding: 1rem 1rem 1rem 1rem; */
     height: 100%;
    
 }
 
 .tab_2 .tab{
     font-size: 2rem;
     
     color: black;
     font-family: 'Inter',sans-serif;
 }
 
 .tab_1 .tab{
     font-size: 2rem;
     font-weight: 800;
     font-family: 'Inter',sans-serif;
     color: black;
 }

 .tab{
    margin: 0;
 }

 
 .form_container{
     z-index: 99;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     padding: 10px;
     margin-top: 20px;

 }
 
 .form{
     display: flex;
     /* justify-content: center; */
     align-items: center;
     width: 100%;
     flex-direction: column;
     gap: 2rem;
     /* height: 20rem; */
 }
 
 .input{
    width: 100%;
     border: none;
     outline: none;
     background: transparent;
     height: 55px;
     color: black;
     font-size: 16px;
     font-family: 'Inter',sans-serif;
     /* font-weight:500; */
 }
 
 .input::placeholder{
     color: grey;
 }
 
 .input_group{
     width: 100%;
     background-color: #eff2f5;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     gap: 1rem;
     padding: 0 1rem 0 1rem;
     border-radius: 0.4rem;
 }
 
 .country_code{
     color: grey;
     font-size: 16px;
     font-weight: 800;
 }
 
 .selector{
     /* width: 25rem; */
     gap: 0.5rem;
     width: 90%;
     font-size: 1.3rem !important;
     cursor: pointer;
 }
 
 
 .subscribe_btn{
     /* margin-top: 8rem; */
     width: 90%;
     /* width:15rem; */
     /* width: 27rem; */
     display: flex;
     justify-content:center;
     align-items: center;
     padding: 1rem 1rem 1rem 1rem;
     font-size: 2rem;
     font-family: 'Inter',sans-serif;
     font-weight: 900;
     color: white;
     background-color: #E92230;
     text-transform: uppercase;
     /* letter-spacing: 0.2rem; */
     border: none;
     outline: none;
     border-radius: 1rem;
     box-shadow: 0 1rem 1rem rgba(0,0,0,0.3);
     cursor: pointer;
 }
 
 
 .footer_container{
    padding: 2rem;
    position: absolute;
    /* / left: 0; / */
    bottom:10px;
    /* / margin-top: 8rem; / */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 99;
 }

 .footer{
    height: 6rem;
    width: 10rem;
 }

 .footer_sub_container{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     /* gap: 1rem; */
 }

 .footer_text{
     /* padding: 1rem 1rem 1rem 1rem; */
     /* font-size: 1.4rem; */
     font-family: 'Inter',sans-serif;
     color: grey;
     text-align: center;
 }
