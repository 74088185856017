.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;
    /* / height: 100dvh; / */

}
.top{
    width: 70%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: white;
    top: 0;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.image{
    top: 5rem;
    /* / position: inherit; / */
    padding: 2rem;
}

.animation{
    height: 8rem;
    width: 8rem;
    /* / position: absolute; / */
    bottom: 5rem;
    margin-top: 3rem;
}

.footer_container{
    padding: 2rem;
    position: absolute;
    /* / left: 0; / */
    bottom:0;
    /* / margin-top: 8rem; / */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 99;
}

.footer{
   height: 6rem;
   width: 10rem;
}

.footer_sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* / gap: 1rem; / */
}